<template>
    <div class="financiallist">
        <div class="padd20 borbut20 " style="position: relative;">
            <div class="financiallistFrome active">
                <!-- <div class="financiallistFrome active" :class="spredPackup ? 'active' : ''"> -->
                <div class="topSearchSou">
                    <div class="searchdan time">
                        <div class="title">开始时间：</div>
                        <el-date-picker v-model="start_created_at" :picker-options="pickerOptions0" type="date" placeholder="开始时间" style="width:180px"></el-date-picker>
                    </div>
                    <div class="searchdan time">
                        <div class="title">结束时间：</div>
                        <el-date-picker v-model="end_created_at" type="date" :picker-options="pickerOptions1" placeholder="结束时间" style="width:180px"></el-date-picker>
                    </div>
                    <div class="searchdan">
                        <div class="title">端口名称：</div>
                        <PortSearch ref="PortSearch"></PortSearch>
                    </div>
                    <div class="searchdan">
                        <span class="title" style="margin-right:15px; margin-left:15px;">企业名称：</span>
                        <el-select v-model="corporate_account_id" placeholder="请输入企业" filterable remote clearable @change="currentSel" reserve-keyword :remote-method="Tolikeqy">
                            <div v-infinite-scroll="qiyeload" style="" class="flock-load">
                                <el-option v-for="item in qiyelist" :key="item.value" :label="item.corporate_account_name" :value="item.id">
                                </el-option>
                            </div>
                        </el-select>
                    </div>
                    <div class="searchdan">
                        <span class="title" style="margin-left:15px;">群名称：</span>
                        <el-select v-model="company_id" filterable placeholder="请输入群名" remote clearable @change="currentSel" reserve-keyword :remote-method="Tolikesearch">
                            <div v-infinite-scroll="qunload" style="" class="flock-load">
                                <el-option v-for="item in qunlist" :key="item.value" :label="item.company_name" :value="item.id">
                                </el-option>
                            </div>
                        </el-select>
                        <!-- <el-input v-model="account_port_id" placeholder="请输名称"></el-input> -->
                    </div>
                    <div class="searchdan">
                        <span class="title">收款账户：</span>
                        <el-select v-model="bank_card_id" placeholder="请输入账户">
                            <!-- <div v-infinite-scroll="onPageScrollBank" style="" class="flock-load"> -->
                            <el-option v-for="item in bankcard" :key="item.id" :label="item.account_name" :value="item.id">
                            </el-option>
                            <!-- </div> -->
                        </el-select>
                    </div>
                    <div class="searchdan">
                        <span class="title">充值账户：</span>
                        <el-select v-model="agent_account_id" filterable placeholder="请输入账户" remote clearable reserve-keyword :remote-method="onTopAgentremote">
                            <!-- <div v-infinite-scroll="onPageTopAgen" style="" class="flock-load"> -->
                            <el-option v-for="item in TopAgenList" :key="item.id" :label="item.agent_account_name + ' _ ' + item.agent_account + ' _ ' + (item.is_operating_type == 1 ? '自运营' : item.is_operating_type == 2 ? '代运营' : '')" :value="item.id">
                            </el-option>
                            <!-- </div> -->
                        </el-select>
                    </div>
                    <div class="searchdan">
                        <span class="title">运营类型：</span>
                        <el-select v-model="is_operating_type" placeholder="请选择运营类型">
                            <el-option v-for="item in AccountOptionsList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="RightBotton">
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right' style="margin-left:0;">重置数据</el-button>
                <!-- <span class="Spanrefresh" @click="$router.go(0)">重置数据</span> -->
            </div>
            <!-- <div class="spred" @click="onSpred">{{spredPackup ? '收起' : '展开更多筛选'}} <i :class="spredPackup ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" /></div> -->
        </div>
        <div class="finanOrderStatus padd20 borbut20 ">
            <div class="finantitle">订单状态：</div>
            <div class="statusList">
                <div class="dan" v-for="(item,key) in OrderStatus" :key="key" :class="order_status == item.value ? 'active' : ''" @click="onOrderStatus(item)">{{item.label}}</div>
            </div>
        </div>
        <div class="group-table padd20">
            <div class="duoshao">
                <div class="title">订单统计</div>
                <!-- <el-button type="primary" class="xinqiye" icon="el-icon-plus" @click="onNewGroup('')">新增群</el-button> -->
                <div class="lvtotal"><a class="download" @click="onExport" target="_blank">导出<i class="iconfont icon-xiazai"></i></a> 总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="companyList" style="width: 100%">
                <el-table-column prop="account_port.advertiser_full_name" label="订单编号">
                    <template slot="header" slot-scope="scope">
                        订单编号
                        <el-tooltip class="item" effect="dark" content="订单编号取值订单尾数后五位" placement="top">
                            <i class="el-icon-info"></i>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        {{scope.row.order_num.slice(-5)}}
                    </template>
                </el-table-column>
                <el-table-column prop="account_port.advertiser_full_name" label="端口"></el-table-column>
                <el-table-column prop="account_port.advertiser_full_name" label="客户名称">
                    <template slot-scope="scope">
                        {{scope.row.corporate_account ? scope.row.corporate_account.corporate_account_name : '--'}}
                    </template>
                </el-table-column>
                <el-table-column prop="account_port.advertiser_full_name" label="群名称">
                    <template slot-scope="scope">
                        {{scope.row.company_account ? scope.row.company_account.company_name : '--'}}
                    </template>
                </el-table-column>
                <el-table-column prop="agent_account_name" label="合同">
                    <template slot-scope="scope">
                        <span v-if="scope.row.corporate_account.is_attachment == 1">未上传</span>
                        <span v-else style="color:#0075f7">已上传</span>
                    </template>
                </el-table-column>
                <el-table-column prop="agent_account_name" label="账户名称"></el-table-column>
                <el-table-column prop="agent_account" label="账户ID"></el-table-column>
                <el-table-column prop="id" label="运营类型">
                    <template slot-scope="scope">
                        {{scope.row.is_operating_type == 1 ? '自运营' : scope.row.is_operating_type == 2 ? '代运营' : scope.row.is_operating_type == 3 ? '渠道' :  scope.row.is_operating_type == 4 ? 'W渠道' :  scope.row.is_operating_type == 5 ? 'cid' : "--"}}
                    </template>
                </el-table-column>
                <el-table-column prop="rebates" label="返点" width="50"></el-table-column>
                <el-table-column prop="bank_card.card_status" label="对公/对私">
                    <template slot-scope="scope">
                        {{scope.row.bank_card.card_status == 1 ? '对公' : scope.row.bank_card.card_status == 2 ? '对私' : "--"}}
                    </template>
                </el-table-column>
                <el-table-column prop="order_price" label="充值人民币">
                    <template slot-scope="scope">
                        {{ThousandSeparator(scope.row.order_price)}}
                    </template>
                </el-table-column>
                <el-table-column prop="should_price" label="应充值广告币">
                    <template slot-scope="scope">
                        {{ThousandSeparator(scope.row.should_price)}}
                    </template>
                </el-table-column>
                <el-table-column prop="user_record_price" label="实充值广告币">
                    <template slot-scope="scope">
                        {{ThousandSeparator(scope.row.actual_price)}}
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="打款人" width="80">
                    <template slot-scope="scope">
                        --
                    </template>
                </el-table-column>
                <el-table-column prop="bank_card.account_name" label="收款人"></el-table-column>
                <el-table-column prop="id" label="商务负责人">
                    <template slot-scope="scope">
                        {{scope.row.operating ? scope.row.operating.operating_name : '--'}}
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="创建订单时间" width="100"></el-table-column>
                <el-table-column prop="updated_at" label="订单完成时间" width="100"></el-table-column>
            </el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
        </div>
        <!-- 新增群 -->
    </div>
</template>

<script>
import PortSearch from "@/views/commonSearch/portSearch.vue"; //端口选择框
export default {
    data () {
        return {
            paramData: {},//表格参数
            account_port_id: "",
            companyList: [], //群列表
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1,//表格当前第几页
            start_created_at: "", //开始时间
            end_created_at: "", //结束时间
            pickerOptions0: {
                disabledDate: (time) => {
                    if (this.end_created_at != "") {
                        return (
                            time.getTime() >
                            new Date(this.end_created_at).getTime()
                        );
                    }
                },
            },
            pickerOptions1: {
                disabledDate: (time) => {
                    return (
                        time.getTime() <
                        new Date(this.start_created_at).getTime()
                    ); //减去一天的时间代表可以选择同一天;
                },
            },
            hostpath: window.location.host,
            qunlist: {},
            qiyelist: {},
            Company: 1, //群分页
            PageTopCorporateAccountList: "", //企业分页
            corporate_account_id: "", //企业id
            company_id: "", //群id
            bank_card_id: "", //获取银行卡
            bankcard: [], //银行卡列表
            PageTopBankCardList: 1, //银行卡页码
            AccountOptionsList: [ //运营类型列表
                {
                    value: '1',
                    label: '自运营'
                }, {
                    value: '2',
                    label: '代运营'
                }, {
                    value: '3',
                    label: '渠道'
                }, {
                    value: '4',
                    label: 'W渠道'
                }, {
                    value: '5',
                    label: 'cid'
                }
            ],
            is_operating_type: '',//运营类型选择
            spredPackup: false,
            OrderStatus: [//订单状态列表
                {
                    value: '',
                    label: '全部'
                }, {
                    value: '2',
                    label: '处理完成'
                }, {
                    value: '1',
                    label: '处理中'
                }, {
                    value: '3',
                    label: '未处理'
                },
            ],
            order_status: 2,//订单状态参数
            PageTopAgen: 1,//充值账户下拉加载分页
            TopAgenList: [],//充值账户列表
            agent_account_id: '',//充值账户选择
        };
    },
    components: {
        PortSearch,
    },

    mounted () {
        this.ongetTopCompanyList();
        this.getTopCompanyList(); //获取群列表
        this.animateWidth(); //获取企业列表
        this.Tolikezh()//获取收款账户
        this.ongetTopAgentAccountList()//获取充值账户
    },
    methods: {
        //导出
        onExport () {
            var UrlParam = '?account_port_id=' + (this.paramData.account_port_id ? this.paramData.account_port_id : '') +
                '&corporate_account_id=' + (this.paramData.corporate_account_id ? this.paramData.corporate_account_id : '') +  //企业id
                '&company_id=' + (this.paramData.company_id ? this.paramData.company_id : '') +  //群id
                '&start_created_at=' + (this.paramData.start_created_at ? this.dateFtt("yyyy-MM-dd", this.start_created_at) : "") +
                '&end_created_at=' + (this.paramData.end_created_at ? this.dateFtt("yyyy-MM-dd", this.end_created_at) : "") +
                '&bank_card_id=' + (this.paramData.bank_card_id ? this.paramData.bank_card_id : '') +  //收款账户
                '&agent_account_id=' + (this.paramData.agent_account_id ? this.paramData.agent_account_id : '') +  //充值账户
                '&is_operating_type=' + (this.paramData.is_operating_type ? this.paramData.is_operating_type : '') +  //运营类型
                '&order_status=' + (this.paramData.order_status ? this.paramData.order_status : '') //订单状态
            window.open(this.$api.getTopOrderDetailJoinOrderExport + UrlParam)
        },
        //充值账户搜索
        onTopAgentremote (text) {
            this.PageTopAgen = 1
            this.TopAgenList = []
            this.ongetTopAgentAccountList(text)
        },
        //充值账户下拉加载
        onPageTopAgen () {
            this.PageTopAgen++
            this.ongetTopAgentAccountList()
        },
        //充值账户
        ongetTopAgentAccountList (text) {
            var param = {
                page: this.PageTopAgen,
                agent_account: text
            };
            this.$service.get(this.$api.getTopAgentAccountList, param, (res) => {
                if (res.code == "200") {
                    // if (res.data.data.length == 10 || res.data.data.length > 0) {
                    // var TopAgenList = this.TopAgenList;
                    // res.data.data.forEach(function (item, index) {
                    //     TopAgenList.push(item);
                    // });
                    this.TopAgenList = res.data.data;
                    // console.log(this.TopAgenList,123)
                    // } else {
                    // this.PageTopAgen--;
                    // }
                }
            });
        },
        //订单状态点击
        onOrderStatus (item) {
            this.order_status = item.value
            this.ongetTopCompanyList()
        },
        //展开更多搜索条件
        onSpred () {
            this.spredPackup = !this.spredPackup
        },
        //收款账户下拉加载
        // onPageScrollBank(){
        //     this.PageTopBankCardList++
        //     this.Tolikezh()
        // },
        //收款账户获取
        Tolikezh (query) {
            this.yczh = query;
            var param = {
                page: this.PageTopBankCardList,
                account_name: query,
                per_page: 100
            };
            this.$service.get(this.$api.getTopBankCardList, param, (res) => {
                if (res.code == "200") {
                    if (res.data.data.length == 10 || res.data.data.length > 0) {
                        var bankcard = this.bankcard;
                        res.data.data.forEach(function (item, index) {
                            bankcard.push(item);
                        });
                        this.bankcard = bankcard;
                    } else {
                        this.PageTopBankCardList--;
                    }
                }
            });
        },
        //获取企业列表
        animateWidth () {
            var param = {
                page: 1,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.qiyelist = res.data.data;
                    }
                }
            );
        },
        // 企业
        Tolikeqy (query) {
            this.corporate_account_id = query;
            var param = {
                page: 1,
                corporate_account_name: query,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.qiyelist = res.data.data;
                    }
                }
            );
        },
        //企业触底事件
        qiyeload () {
            if (this.corporate_account_id) {
                return;
            }
            this.PageTopCorporateAccountList++;
            var param = {
                page: this.PageTopCorporateAccountList,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    var data = res.data.data;
                    if (res.code == "200") {
                        if (res.data.per_page == 10 || res.data.per_page > 0) {
                            var bankcard = this.qiyelist;
                            data.forEach(function (item, index) {
                                bankcard.push(item);
                            });
                            this.qiyelist = bankcard;
                        } else {
                            this.PageTopCorporateAccountList--;
                        }
                    }
                }
            );
        },
        //群
        //获取群列表
        getTopCompanyList () {
            var param = {
                page: 1,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.qunlist = res.data.data;
                }
            });
        },
        // 群搜索
        currentSel () {
            //    console.log(this.bank_card_id)
        },
        //搜索发起请求 传入值为当前select输入的值
        Tolikesearch (query) {
            this.company_id = query;
            var param = {
                page: 1,
                company_name: query,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.qunlist = res.data.data;
                }
            });
        },
        //群触底事件
        qunload () {
            if (this.company_id) {
                return;
            }
            this.Company++;
            var param = {
                page: this.Company,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                var data = res.data.data;
                if (res.code == "200") {
                    if (res.data.per_page == 10 || res.data.per_page > 0) {
                        var qunlist = this.qunlist;
                        data.forEach(function (item, index) {
                            qunlist.push(item);
                        });
                        this.qunlist = qunlist;
                    } else {
                        this.Company--;
                    }
                }
            });
        },
        //搜索
        onButtonInquire () {
            this.paramData = {
                account_port_id: this.account_port_id,
                corporate_account_id: this.corporate_account_id, //企业id
                company_id: this.company_id, //群id
                start_created_at: this.start_created_at ? this.dateFtt("yyyy-MM-dd", this.start_created_at) : "",
                end_created_at: this.end_created_at ? this.dateFtt("yyyy-MM-dd", this.end_created_at) : "",
                bank_card_id: this.bank_card_id,//收款账户
                agent_account_id: this.agent_account_id,//充值账户
                is_operating_type: this.is_operating_type,//运营类型
                order_status: this.order_status,//订单状态
            }
            this.paramPage = 1
            this.ongetTopCompanyList()
        },
        //订单统计
        ongetTopCompanyList () {
            this.companyList = [];
            var param = this.paramData
            param.page = this.paramPage
            this.paramData.order_status = this.order_status
            this.$service.get(this.$api.getTopOrderDetailJoinOrder, param, (res) => {
                if (res.code == "200") {
                    this.companyList = res.data.data;
                    this.lvtotal = res.data.total;
                }
            }
            );
        },
        // 分页
        DialogCurrentChange (val) {
            this.paramPage = val
            this.ongetTopCompanyList()
        },
        //端口id接收参数****用端口名称必须用这个接收参数
        onPropChange (data) {
            this.account_port_id = data.id;
        },
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.financiallist {
    .financiallistFrome {
        position: relative;
        display: flex;
        // align-items: center;
        height: 40px;
        transition: 0.5s;
        &.active {
            transition: 0.5s;
            height: 80px;
        }
        .RightBotton {
            width: 150px;
        }
        .topSearchSou {
            overflow: hidden;
            .searchdan {
                margin-bottom: 10px;
            }
            .searchdan:first-child {
                margin-left: 20px;
            }
        }
    }
}
</style>
<style lang="scss">
.financiallist {
    .topSearchSou {
        width: calc(100% - 150px);
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        .searchdan {
            &.time {
            }
        }
    }
}
</style>